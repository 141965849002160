import type { ReactNode } from 'react'

import { cn } from '~/ui/lib/utils'

const HelperText = ({
	children,
	className,
}: {
	children: ReactNode
	className?: string
}) => {
	const classList = cn(
		'text-xs font-light italic text-slate-600 -tracking-[0.576px]',
		className,
	)

	return (
		<p className={classList}>
			{children}
		</p>
	)
}

export default HelperText
