'use client'

import { useState } from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { usePathname } from 'next/navigation'

import { Button } from '~/ui/shadcn/ui/button'
import { cn } from '~/ui/lib/utils'

import type { LinkItem } from './navigation-items-list'

const DesktopNavigationItem = ({
	item,
}: {
	item: LinkItem
}) => {
	const pathname = usePathname()

	const [
		hoveredNavigationItem,
		setHoveredNavigationItem,
	] = useState<string | null>(null)

	const itemHref = typeof item.linkProps.href === 'string' ? item.linkProps.href : item.linkProps.href.pathname

	if (!itemHref) {
		return null
	}

	const isHovered = hoveredNavigationItem === itemHref
	const isCurrentPage = itemHref === pathname

	return (
		<div
			key={itemHref}
			onMouseEnter={() => {
				setHoveredNavigationItem(itemHref)
			}}
			onMouseLeave={() => {
				setHoveredNavigationItem(null)
			}}
			className="relative"
		>
			<Link
				{...item.linkProps}
				className="relative z-20 inline-block rounded-full bg-transparent no-underline hover:bg-transparent"
			>
				<Button
					className={cn(
						'rounded-md bg-transparent text-black/80 shadow-none',
						isCurrentPage && 'text-tremor-brand',
					)}
				>
					{item.label}
				</Button>
			</Link>
			{isHovered && !isCurrentPage ? (
				<motion.span
					layoutId="hover"
					transition={{
						type: 'spring',
						duration: 0.4,
					}}
					className="absolute inset-0 size-full rounded-md bg-gray-300 transition-colors duration-500"
				/>
			) : null}
			{isCurrentPage ? (
				<motion.span
					layoutId="hover"
					transition={{
						type: 'spring',
						duration: 0.4,
					}}
					className="absolute inset-0 size-full rounded-md bg-tremor-brand-muted text-tremor-brand transition-colors duration-500"
				/>
			) : null}
		</div>
	)
}

export default DesktopNavigationItem
