import type { OrganizationMembershipRole } from '@clerk/backend'

import { useDebounceValue } from 'usehooks-ts'
import { useOrganizationList } from '@clerk/shared/react'
import { Loader } from 'lucide-react'
import Image from 'next/image'
import { useRouter } from 'next/navigation'

import { client } from '~/app/_trpc/client'
import {
	CommandInput,
	CommandEmpty,
	CommandGroup,
	CommandItem,
	Command,
} from '~/ui/shadcn/ui/command'
import { Badge } from '~/ui/shadcn/ui/badge'
import { cn } from '~/ui/lib/utils'

const RoleBadge = ({
	role,
}: {
	role: OrganizationMembershipRole
}) => {
	const baseClassName = 'rounded-md px-1'

	switch (role) {
		case 'basic_member':
			return (
				<Badge className={cn(baseClassName, 'hover:bg-blue-700 bg-blue-700 text-white')}>
					Reviewer
				</Badge>
			)
		case 'org:admin':
			return (
				<Badge className={cn(baseClassName, 'hover:bg-red-700 bg-red-700 text-white')}>
					Admin
				</Badge>
			)
	}
}

const SelectContent = ({
	setOpen,
}: {
	setOpen: (open: boolean) => void
}) => {
	const [
		organizationSearchQuery,
		setOrganizationSearchQuery,
	] = useDebounceValue('', 420)

	const {
		setActive,
		isLoaded,
	} = useOrganizationList()

	const {
		data: memberships,
		isLoading,
	} = client.clerk.searchOrganizations.useQuery({
		query: organizationSearchQuery,
	}, {
		cacheTime: 0,
	})

	const router = useRouter()

	return (
		<Command shouldFilter={false}>
			{memberships && memberships.length > 5 ? (
				<CommandInput
					defaultValue={organizationSearchQuery}
					onValueChange={(value) => {
						setOrganizationSearchQuery(value)
					}}
					placeholder="Search for an Organization..."
				/>
			) : null}
			{isLoading || !isLoaded ? (
				<div className="flex items-center justify-center p-3">
					<Loader className="size-4 animate-spin" />
				</div>
			) : (
				<>
					<CommandEmpty className="p-2">
						No Organization found.
					</CommandEmpty>
					<CommandGroup className="p-2">
						{memberships?.map((membership) => {
							return (
								<CommandItem
									key={membership.id}
									value={membership.organization.id}
									onSelect={async () => {
										await setActive({
											organization: membership.organization.id,
										})
										router.push('/')
										setOpen(false)
									}}
									className="flex cursor-pointer items-center justify-between gap-3"
								>
									<div className="flex items-center gap-3">
										<div className="relative aspect-square size-8 rounded bg-black">
											{membership.organization.hasImage ? (
												<Image
													src={membership.organization.imageUrl}
													priority={true}
													alt={membership.organization.name}
													title={membership.organization.name}
													className="rounded object-cover"
													fill={true}
												/>
											) : null}
										</div>
										<div>
											{membership.organization.name}
										</div>
									</div>
									<RoleBadge role={membership.role} />
								</CommandItem>
							)
						})}
					</CommandGroup>
				</>
			)}
		</Command>
	)
}

export default SelectContent
