import { fromUnixTime, formatISO } from 'date-fns'

export const dateTimeFormat = (rawTimeStamp: Date, dateOnly: boolean) => {
	return new Date(rawTimeStamp).toLocaleString('en-US', {
		year:'numeric',
		month:'short',
		day:'numeric',
		...dateOnly ? null : {
			hour:'numeric',
			minute:'numeric',
			timeZoneName:'short',
		},
	})
}

export const convertEpochToISO8601 = (epochTime: number): string => {
	// Convert epoch time from milliseconds to seconds
	const epochSeconds = Math.floor(epochTime / 1000)

	// Convert epoch seconds to Date object
	const date = fromUnixTime(epochSeconds)

	// Format the Date object to ISO-8601 string
	return formatISO(date)
}

