'use client'

import { useState } from 'react'
import { useOrganizationList } from '@clerk/shared/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { Button } from '~/ui/shadcn/ui/button'
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
} from '~/ui/shadcn/ui/popover'
import SelectContent from '~/app/(app)/_modules/header/organization-select/select-content'

const OrganizationSelect = () => {
	const [
		open,
		setOpen,
	] = useState(false)

	const {
		userMemberships,
	} = useOrganizationList({
		userMemberships: true,
	})
	const count = userMemberships.data?.length || 0

	if (count <= 1) {
		return null
	}

	return (
		<Popover
			open={open}
			onOpenChange={setOpen}
			modal={true}
		>
			<PopoverTrigger asChild>
				<Button
					className="border-transparent bg-transparent px-0 py-2 text-neutral-400 shadow-none"
					size="xs"
				>
					<ChevronDownIcon className="size-6" />
				</Button>
			</PopoverTrigger>
			<PopoverContent className="max-h-48 overflow-y-auto p-0">
				<SelectContent setOpen={setOpen} />
			</PopoverContent>
		</Popover>
	)
}

export default OrganizationSelect
