'use client'

import { useState } from 'react'
import Link from 'next/link'
import {
	Bars3Icon,
	BuildingOfficeIcon,
	IdentificationIcon,
	Cog6ToothIcon,
	BellIcon,
} from '@heroicons/react/24/solid'
import { usePathname } from 'next/navigation'
import {
	AnimatePresence,
	easeInOut,
	motion,
} from 'framer-motion'
import { SignOutButton, useUser } from '@clerk/nextjs'

import { Button } from '~/ui/shadcn/ui/button'
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from '~/ui/shadcn/ui/avatar'
import { cn } from '~/ui/lib/utils'

const MobileNavMenu = () => {
	const [
		isMenuShowing,
		setIsMenuShowing,
	] = useState(false)
	const pathName = usePathname()

	const {
		user: clerkUser,
	} = useUser()

	const menuVariants = {
		hidden: {
			height: 0,
			opacity: 0,
		},
		visible: {
			height: '55vh',
			opacity: 1,
			transition: {
				duration: 0.3,
			},
		},
		exit: {
			height: '0vh',
			opacity: 1,
			transition: {
				duration: 0.15,
				delay: 0.08,
				ease: 'easeInOut',
			},
		},
	}

	const linkVariants = {
		hidden: {
			opacity: 0,
		},
		visible: (i: number) => {
			return {
				opacity: 1,
				transition: {
					delay: 0.2 + i * 0.05,
					duration: 0.2,
				},
			}
		},
		exit: (i: number) => {
			return {
				opacity: 0,
				transition: {
					delay: 0.05 + (links.length - i - 1) * 0.005,
					duration: 0.15,
				},
			}
		},
	}

	const avatarVariants = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				duration: 0.3,
				delay: 0.3,
				ease: easeInOut,
			},
		},
		exit: {
			opacity: 0,
			transition: {
				duration: 0.2,
				ease: easeInOut,
			},
		},
	}

	const links = [
		{
			href: '/projects',
			label: 'Projects',
			icon: <BuildingOfficeIcon
				className={cn('mr-2 size-6', {
					'text-tremor-brand': pathName === '/projects',
				})}
			/>,
		},
		{
			href: '/vendors',
			label: 'Vendors',
			icon: <IdentificationIcon
				className={cn('mr-2 size-6', {
					'text-tremor-brand': pathName === '/vendors',
				})}
			/>,
		},
		{
			href: '/settings',
			label: 'Settings',
			icon: <Cog6ToothIcon
				className={cn('mr-2 size-6', {
					'text-tremor-brand': pathName === '/settings',
				})}
			/>,
		},
		{
			href: '/inbox/mobile',
			label: 'Inbox',
			icon: <BellIcon
				className={cn('mr-2 size-6', {
					'text-tremor-brand': pathName === '/inbox',
				})}
			/>,
		},
	]

	return (
		<div>
			<div
				onClick={() => {
					setIsMenuShowing(false)
				}}
				className={`${isMenuShowing && 'fixed inset-0 z-40 mt-20 h-screen w-screen overflow-hidden bg-white/60 backdrop-blur-sm'}`}
			/>
			<div>
				<Button
					size="icon"
					className={`relative size-8 bg-transparent shadow-none hover:bg-transparent active:bg-tremor-brand-muted ${isMenuShowing && 'bg-tremor-brand-muted hover:bg-tremor-brand-muted active:bg-tremor-brand-muted'}`}
					onClick={() => {
						setIsMenuShowing(!isMenuShowing)
					}}
				>
					{isMenuShowing ? <Bars3Icon className="size-6 text-tremor-brand" /> : <Bars3Icon className="size-6 text-gray-600" />}
				</Button>
			</div>
			<AnimatePresence>
				{isMenuShowing ? (
					<motion.div
						key="menu"
						initial="hidden"
						animate="visible"
						exit="exit"
						variants={menuVariants}
						className="absolute left-0 top-0 z-50 mt-20 flex w-screen flex-col overflow-hidden bg-neutral-100 shadow-xl"
					>
						<div className="flex h-3/4 flex-col justify-around border-b border-b-neutral-200 pb-4 pt-3">
							{links.map((link, i) => {
								return (
									<motion.div
										key={link.href}
										custom={i}
										variants={linkVariants}
										className="h-14"
									>
										<Link
											onClick={() => {
												setIsMenuShowing(false)
											}}
											className={`${
												pathName === link.href ? 'bg-tremor-brand-muted' : 'bg-neutral-100'
											} ml-2 mr-3 flex h-full items-center justify-start rounded-md p-2 text-black`}
											href={link.href}
										>
											{link.icon}
											<p className={`text-lg font-medium ${
												pathName === link.href && 'text-tremor-brand'
											}`}
											>
												{link.label}
											</p>
										</Link>
									</motion.div>
								)
							})}
						</div>
						<motion.div
							initial="hidden"
							animate="visible"
							exit="exit"
							variants={avatarVariants}
							className="flex h-[30%] items-center justify-between"
						>
							<Link
								href="/settings/profile"
								onClick={() => {
									setIsMenuShowing(false)
								}}
								className="m-4 flex h-full items-center justify-start"
							>
								<Avatar className="mr-2 size-14">
									<AvatarImage src={clerkUser?.imageUrl} />
									<AvatarFallback>
										{Array.from(clerkUser?.firstName || '')[0]}
										{Array.from(clerkUser?.lastName || '')[0]}
									</AvatarFallback>
								</Avatar>
								<h3 className="text-2xl font-medium">
									{clerkUser?.firstName}
									{' '}
									{clerkUser?.lastName}
								</h3>
							</Link>
							<div className="mr-5">
								<SignOutButton>
									<Button variant="outline">
										Logout
									</Button>
								</SignOutButton>
							</div>
						</motion.div>
					</motion.div>
				) : null}
			</AnimatePresence>
		</div>
	)
}

export default MobileNavMenu
