'use client'

import {
	XIcon,
	ArrowRightIcon,
	Loader2Icon, ArrowRight,
} from 'lucide-react'
import Link from 'next/link'
import posthog from 'posthog-js'

import HelperText from '~/modules/typography/helper-text'
import { Button } from '~/ui/shadcn/ui/button'
import { client } from '~/app/_trpc/client'
import { dateTimeFormat } from '~/utils/date-time-format'

const VendorReviewRequestList = ({
	onRoute,
}: {
	onRoute: () => Promise<void> | void
}) => {
	const trpcContext = client.useUtils()

	const {
		data: notifications,
		isLoading: isLoadingNotifications,
	} = client.reviewRequests.getReviewRequestNotifications.useQuery({
		limit: 5,
	})

	const {
		mutateAsync: dismissReviewRequest,
		isLoading: isLoadingDismissal,
	} = client.reviewRequests.dismissReviewRequest.useMutation({
		onSuccess: async () => {
			await trpcContext.reviewRequests.invalidate()
		},
	})

	if (isLoadingNotifications || !notifications) {
		return (
			<div className="px-6 py-3 text-center">
				<Loader2Icon className="mx-auto animate-spin" />
			</div>
		)
	}

	return (
		<div className="overflow-hidden rounded-sm border border-slate-200 bg-white shadow-sm">
			<div className="flex flex-row items-center justify-between border-b border-b-slate-200 bg-slate-100 px-4 py-2">
				<h3>
					Vendor Review Requests
				</h3>
				<a
					href="/inbox"
					onClick={async () => {
						posthog.capture('vendor_inbox_visit', {
							device: 'Desktop',
						})
						await onRoute()
					}}
				>
					<Button
						variant="outline"
						size="xs"
						className="text-xs"
					>
						Go to Vendor Review Inbox
						{' '}
						<ArrowRight className="size-3" />
					</Button>
				</a>
			</div>
			<div className="space-y-2 px-4 py-2">
				{notifications.length > 0 ? notifications.map((notification) => {
					return (
						<div key={notification.id}>
							<div className="flex cursor-pointer items-center justify-between space-x-6 hover:bg-none">
								<Link
									className="flex grow flex-row items-center justify-between gap-6"
									href={`/submit-review/${notification.vendor.id}?shortCode=${notification.shortCode}`}
									onClick={async () => {
										await onRoute()
									}}
								>
									<span className="flex flex-col items-start gap-1">
										<div className="flex flex-row items-center gap-2">
											<div>
												<div className="flex flex-row items-center gap-2">
													{notification.vendor.name}
												</div>
												<HelperText>
													{notification.team.name}
													{' '}
													Team
												</HelperText>
											</div>
										</div>
									</span>
									<span className="flex flex-row items-center gap-2">
										<small className="text-slate-400">
											{dateTimeFormat(notification.createdAt, true)}
										</small>
									</span>
								</Link>
								<div className="flex items-center gap-3">
									<Button
										asChild
										variant="outline"
										size="icon"
										className="size-8"
									>
										<Link
											href={`/submit-review/${notification.vendor.id}?shortCode=${notification.shortCode}`}
											onClick={async () => {
												await onRoute()
											}}
										>
											<ArrowRightIcon className="size-4" />
										</Link>
									</Button>
									<Button
										variant="destructive"
										size="icon"
										className="size-8"
										onClick={async () => {
											await dismissReviewRequest({
												reviewRequestId: notification.id,
											})
											await trpcContext.reviewRequests.invalidate()
										}}
									>
										{isLoadingDismissal ? (
											<Loader2Icon className="size-4" />
										) : (
											<XIcon className="size-4" />
										)}
									</Button>
								</div>
							</div>
						</div>
					)
				}) : (
					<div className="px-6 py-3 text-center">
						No Pending Requests
					</div>
				)}
			</div>
		</div>
	)
}

export default VendorReviewRequestList
